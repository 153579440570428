import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import config from "../../config";
import { useHistory } from "react-router";
import { BsArrowRightShort } from "react-icons/bs";

const ProductGridListSingle = ({
  product,
  currency,
  addToCart,
  decreaseQuantity,
  deleteFromCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  layout,
  parent,
  getUser,
  lkey
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const discountedPrice = getDiscountPrice(
    product.price,
    product.sale_value,
    product.on_sale
  );
  const [quantityCount, setQuantityCount] = useState(1);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);

  useEffect(() => {
    if (cartItem && cartItem.quantity && cartItem.stock) {
      console.log('coming', cartItem.stock);
      setQuantityCount(cartItem.stock);
    } else {
      console.log('comming in else');
    }
  }, [cartItem]);

  return (
    <Fragment>
      <div
        className={`col-xl-${layout === "grid three-column" ? 3 : 4} col-sm-6 ${sliderClassName ? sliderClassName : ""
          }`}
        style={{ padding: 0 }}
      >
        <div
          className={`product-wrap product-container ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div
            className="product-img"
            style={{
              padding: 10,
              border: "1px solid #d9d9d9",
              borderRadius: 5,
              // height: 325,
            }}
          >
            <Link to={"/product/" + product.id}>
              <img
                // className="default-img"
                style={{ objectFit: "cover" }}
                src={`${JSON.parse(product.photo)[0]}`}
                alt=""
              />
              {JSON.parse(product.photo).length > 1 ? (
                <img
                  className="hover-img"
                  src={`${JSON.parse(product.photo)[1]}`}
                  alt=""
                />
              ) : (
                ""
              )}
            </Link>

            <div className="product-img-badges">
              {product.on_sale ? (
                <span className="pink">-{product.sale_value}%</span>
              ) : (
                ""
              )}
              {product.best_seller ? <span className="purple">Best</span> : ""}
            </div>
            {parent !== 'store' ? <div className="product-action">
              <div className="pro-same-action pro-wishlist">
                <button
                  className={wishlistItem !== undefined ? "active" : ""}
                  disabled={wishlistItem !== undefined}
                  title={
                    wishlistItem !== undefined
                      ? "Added to wishlist"
                      : "Add to wishlist"
                  }
                  onClick={() => addToWishlist(product, addToast)}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
              <div className="pro-same-action pro-cart">
                {product.affiliateLink ? (
                  <a
                    href={product.affiliateLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {" "}
                    Buy now{" "}
                  </a>
                ) : !product.out_of_stock &&
                  product.product_varients &&
                  product.product_varients.length >= 1 ? (
                  <Link to={`/product/${product.id}`}>Select Option</Link>
                ) : !product.out_of_stock &&
                  product.quantity &&
                  product.quantity > 0 ? (
                  <button
                    onClick={() => {
                      console.log(quantityCount);
                      addToCart(product, addToast, 1, '', '', [], product.pack_size && product.pack_price ? true : false)
                    }
                    }
                    className={
                      cartItem !== undefined && cartItem.quantity > 0
                        ? "active"
                        : ""
                    }
                    disabled={cartItem !== undefined && cartItem.quantity > 0}
                    title={
                      cartItem !== undefined ? "Added to cart" : product.pack_size && product.pack_price ? "Add Pack to cart" : "Add to cart"
                    }
                  >
                    {" "}
                    <i className="pe-7s-cart"></i>{" "}
                    {cartItem !== undefined && cartItem.quantity > 0
                      ? "Added"
                      : product.pack_size && product.pack_price ? "Add Pack to cart" : "Add to cart"}
                  </button>
                ) : (
                  <button disabled className="active">
                    Out of Stock
                  </button>
                )}
              </div>
              <div className="pro-same-action pro-quickview">
                <button onClick={() => setModalShow(true)} title="Quick View">
                  <i className="pe-7s-look" />
                </button>
              </div>
            </div>
              : null
            }
          </div>
          {parent === 'store' ?
            <div
              className="product-img mt-2"
              style={{
                borderRadius: 5,
                height: 45,
              }}
            >
              <div className="product-action-cus">
                <div className="pro-same-action pro-wishlist">
                  <button
                    className={wishlistItem !== undefined ? "active" : ""}
                    disabled={wishlistItem !== undefined}
                    title={
                      wishlistItem !== undefined
                        ? "Added to wishlist"
                        : "Add to wishlist"
                    }
                    onClick={() => addToWishlist(product, addToast)}
                  >
                    <i className="pe-7s-like" />
                  </button>
                </div>
                <div className="pro-same-action pro-cart">
                  {product.affiliateLink ? (
                    <a
                      href={product.affiliateLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      Buy now{" "}
                    </a>
                  ) : !product.out_of_stock &&
                    product.product_varients &&
                    product.product_varients.length >= 1 ? (
                    <Link to={`/product/${product.id}`}>Select Option</Link>
                  ) : !product.out_of_stock &&
                    product.quantity &&
                    product.quantity > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flex: 1
                        }}
                      >
                        <button
                          onClick={() => {
                            if (cartItem && cartItem.stock > 1) {
                              decreaseQuantity(cartItem, addToast)
                            } else {
                              deleteFromCart(cartItem, addToast)
                            }
                          }}
                          disabled={!cartItem}
                        >
                          -
                        </button>
                        <button
                          style={{ cursor: 'none', borderLeft: '1px solid rgba(255, 255, 255, 0.2)', borderRight: '1px solid rgba(255, 255, 255, 0.2)' }}
                        >
                          {`${cartItem !== undefined ? cartItem.stock : 0}`}
                        </button>
                        <button
                          onClick={() => {
                            let cartItemToAdd;
                            if (cartItem && cartItem.stock) {
                              cartItemToAdd = cartItem
                            } else {
                              cartItemToAdd = product;
                            }
                            addToCart(cartItemToAdd, addToast, 1, '', '', [], product.pack_size && product.pack_price ? true : false)
                          }}
                          className={
                            cartItem !== undefined && cartItem.stock >= product.quantity
                              ? "active"
                              : ""
                          }
                          disabled={cartItem !== undefined && cartItem.stock >= product.quantity}
                        >
                          +
                        </button>
                      </div>
                    </>
                  ) : (
                    <button disabled className="active">
                      Out of Stock
                    </button>
                  )}
                </div>
                <div className="pro-same-action pro-quickview">
                  <button onClick={() => setModalShow(true)} title="Quick View">
                    <i className="pe-7s-look" />
                  </button>
                </div>
              </div>
            </div>
            : null
          }
          <div className="product-content text-center">
            <p
              style={{
                fontSize: 14,
                color: "rgb(52, 53, 56)",
                fontFamily: "lato",
                margin: 0,
              }}
            >
              <Link to={"/product/" + product.id}>{product.name}</Link>
            </p>
            {!parent && <p
              style={{
                fontSize: 15,
                color: "#6699CC",
                fontFamily: "lato",
                margin: 0,
              }}
            >
              <Link
                style={{
                  fontSize: 15,
                  color: "#6699CC",
                  fontFamily: "lato",
                  margin: 0,
                }}
                to={"/public/view/store/" + product.sellerId}
              >
                By {product.store.name}
              </Link>
            </p>}
            {/* {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )} */}
            {getUser && Object.keys(getUser).length > 0 ?
              <div className="product-price">
                {discountedPrice !== null ? (
                  <Fragment>
                    <span>Wholesale</span>
                    <span className="amount old">
                      {currency.currencySymbol + finalProductPrice.toFixed(2)}
                    </span>
                    <span className="amount">{currency.currencySymbol + finalDiscountedPrice.toFixed(2)}</span>{" "}
                  </Fragment>
                ) : (
                  <>
                    <span>
                      Wholesale {currency.currencySymbol + finalProductPrice.toFixed(2)}{" "}
                    </span>{" "}
                    <br />
                    {lkey ? <span>Retail {currency.currencySymbol + product.retail_price.toFixed(2)} <br /></span> : null}
                  </>
                )}
                {
                  product.pack_size && product.pack_price ?
                    <Fragment>
                      <span style={{ color: 'grey' }}>Pack of {`${product.pack_size}`}</span>
                    </Fragment>
                    : null
                }
              </div> :
              <div className="product-price">
                <Fragment>
                  <span style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); history.push('/buyer/login'); }}>
                    {`Signup to see prices  `}  <i className="fa fa-sign-in" />
                  </span>
                </Fragment>
              </div>
            }
          </div>
          {!lkey ? <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 10,
              paddingBottom: 10,
              borderBottom: "1px solid black",
              alignSelf: "flex-start",
              cursor: "pointer",
              width: 'fit-content'
            }}
          >
            <a
              style={{
                fontSize: 14,
                color: "black",
                margin: 0,
              }}
              href={"/public/view/store/" + product.sellerId}
            >
              View All Products
            </a>
            <BsArrowRightShort />
          </div> : null}
        </div>

        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                  <Link to={"/product/" + product.id}>
                    <img
                      className="default-img img-fluid"
                      src={`${JSON.parse(product.photo)[0]}`}
                      alt=""
                    />
                    {JSON.parse(product.photo).length > 1 ? (
                      <img
                        className="hover-img img-fluid"
                        src={`${JSON.parse(product.photo)[1]}`}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                  {/* {product.discount || product.new ? ( */}
                  <div className="product-img-badges">
                    {product.on_sale ? (
                      <span className="pink">-{product.sale_value}%</span>
                    ) : (
                      ""
                    )}
                    {product.best_seller ? (
                      <span className="purple">Best</span>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3>
                  <Link to={"/product/" + product.id}>{product.name}</Link>
                </h3>
                {getUser && Object.keys(getUser).length > 0 ?
                  <div className="product-list-price">
                    {discountedPrice !== null ? (
                      <Fragment>
                        <span>
                          {currency.currencySymbol + finalDiscountedPrice.toFixed(2)}
                        </span>{" "}
                        <span className="old">
                          {currency.currencySymbol + finalProductPrice.toFixed(2)}
                        </span>
                      </Fragment>
                    ) : (
                      <>
                        {" "}
                        <span>
                          {currency.currencySymbol + finalProductPrice.toFixed(2)}{" "}
                        </span>{" "}
                        {/* Retail{" "}
                        <span>
                          {" "}
                          {currency.currencySymbol + product.retail_price.toFixed(2)}{" "}
                        </span>{" "} */}
                      </>
                    )}
                  </div> :
                  <div className="product-list-price">
                    <Fragment>
                      <span style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); history.push('/buyer/login') }}>
                        {`Signup to see prices  `}  <i className="fa fa-sign-in" />
                      </span>
                    </Fragment>
                  </div>
                }
                {/* {product.rating && product.rating > 0 ? (
                  <div className="rating-review">
                    <div className="product-list-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                {product.description ? <p>{product.description}</p> : ""}

                <div className="shop-list-actions d-flex align-items-center">
                  <div className="shop-list-btn btn-hover">
                    {product.affiliateLink ? (
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : !product.out_of_stock &&
                      product.product_varients &&
                      product.product_varients.length >= 1 ? (
                      <Link to={`/product/${product.id}`}>Select Option</Link>
                    ) : !product.out_of_stock &&
                      product.quantity &&
                      product.quantity > 0 ? (
                      <button
                        onClick={() => { if (getUser && Object.keys(getUser).length > 0) { addToCart(product, addToast, 1, '', '', [], product.pack_size && product.pack_price ? true : false) } else { history.push('/buyer/login'); } }}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : product.pack_size && product.pack_price ? "Add Pack to cart" : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : product.pack_size && product.pack_price ? "Add Pack to cart" : "Add to cart"}
                      </button>
                    ) : (
                      <button disabled className="active">
                        Out of Stock
                      </button>
                    )}
                  </div>

                  <div className="shop-list-wishlist ml-10">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => addToWishlist(product, addToast)}
                    >
                      <i className="pe-7s-like" />
                    </button>
                  </div>
                  <div className="shop-list-compare ml-10">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => addToCompare(product, addToast)}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        getUser={getUser}
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedprice={discountedPrice}
        finalproductprice={finalProductPrice}
        finaldiscountedprice={finalDiscountedPrice}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        addtoast={addToast}
      />
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  decreaseQuantity: PropTypes.func,
  deleteFromCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object,
};

export default ProductGridListSingle;
