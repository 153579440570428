import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Accordion,
  Button,
  Container,
  Dropdown,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import "./headerStyling.css";
import { FaSearch } from "react-icons/fa";
import { useWindowDims } from "../../../helpers/useDimensions";
import ReworkedSearchBar from "./ReworkedSearchBar";
import navbar_toggle from "../../../assets/navbar_toggle.png";
import { AiOutlineRight } from "react-icons/ai";
import axios from "axios";
import config from "../../../config";
import { Link, useHistory } from "react-router-dom";
import HoverLinks from "./HoverLinks";
import HoverMenu from "./HoverMenu";
import IconGroup from "../../header/IconGroup";
import MobileMenuAccordian from "./MobileMenuAccordian";
import jwt from "jsonwebtoken";

const ReworkedHeaderContainer = ({ search_bar_change }) => {
  const { width, height } = useWindowDims();
  const [toggleSearch, setToggleSearch] = useState(true);
  const [showCollapseMenu, setShowCollapseMenu] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [userLog, setUserLog] = useState(false);

  const [sellerLog, setSellerLog] = useState(false);
  const [sellerLogInfo, setSellerLogInfo] = useState(null);

  const [categories, setCategories] = useState([]);
  const [noticebar, setNoticebar] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [brandImage, setBrandImage] = useState();
  const [currentEventKeyCats, setCurrentEventKeyCats] = useState(null);
  const [showHoverCat, setShowHoverCat] = useState({});
  const [isShowing, setIsShowing] = useState(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <img
      src={navbar_toggle}
      alt=""
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        // onClick(e);
        setShowCollapseMenu(!showCollapseMenu);
      }}
    />
  ));
  useEffect(() => {
    getCategories();

    let user = localStorage.getItem("token_user");
    if (user) {
      setUserLog(true);
    } else {
      setUserLog(false);
    }

    let seller = localStorage.getItem('btap_token');
    if (seller) {
      let decode = jwt.decode(seller);
      setSellerLogInfo(decode);
      setSellerLog(true);
    } else {
      setSellerLogInfo(null);
      setSellerLog(false);
    }
  }, []);

  const getCategories = async () => {
    try {
      const response = await axios.get(`${config.prod}/api/categories/list`);
      setCategories(response.data.data);
      setNoticebar(response.data.noticebar);
    } catch (error) {
      console.log("Error getting categories from db: ", error);
    }
  };

  useEffect(() => {
    console.log({ categories });
  }, [categories]);
  const handleHoverCat = (cat, subCat, secSubCat) => {
    let obj = {
      cat: cat,
      subCat: subCat,
      secSubCat: secSubCat,
    };
    setShowHoverCat(obj);
  };
  return (
    <div className="sticky-nav">
      { noticebar ? 
          <div style={{ backgroundColor: 'black', width: '100%' }}>
            <div style={{ textAlign: 'center', color: 'white', padding: 10 }}>
              {`${noticebar}`}
            </div>
          </div>
        : 
          null
      }
      <Navbar
        collapseOnSelect
        expand="lg"
        // variant="light"
        // bg="light"
        sticky="top"
        style={{
          minHeight: width > 770 ? 90 : 0,
        }}
        //   className="navbar-style-custom"
      >
        <Container fluid>
          {width < 770 && (
            <Dropdown show={showCollapseMenu}>
              <Dropdown.Toggle
                id="dropdown-basic"
                as={CustomToggle}
              ></Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  width: width,
                  height,
                  top: "130%",
                  left: "-16px",
                  padding: 0,
                  border: "none",
                }}
                className="collapse-styling"
              >
                <div
                  className="collapse-item-container-login"
                  style={{ paddingTop: 20, paddingBottom: 20 }}
                >
                  <div className="login-container" style={{ zIndex: 100 }}>
                    {!userLog && (
                      <>
                        {
                          sellerLog && sellerLogInfo.id &&
                            <Button
                              style={{
                                background: "#1D1F23",
                                borderRadius: 7,
                                color: "white",
                                fontSize: 17,
                                fontFamily: "lato",
                                borderColor: "black",
                                minWidth: 150,
                              }}
                              href={`/seller/store/${sellerLogInfo.id}`}
                            >
                              Dashboard
                            </Button>
                        }
                        { !userLog && !sellerLog &&
                          <>
                            <Button
                              style={{
                                background: "#1D1F23",
                                borderRadius: 7,
                                color: "white",
                                fontSize: 17,
                                fontFamily: "lato",
                                borderColor: "black",
                                minWidth: 150,
                              }}
                              href="/buyer/login"
                            >
                              Login
                            </Button>
                            <Dropdown autoClose={false}>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                style={{
                                  background: "#1D1F23",
                                  borderRadius: 7,
                                  color: "white",
                                  fontSize: 17,
                                  fontFamily: "lato",
                                  borderColor: "black",
                                  minWidth: 150,
                                }}
                              >
                                Free Signup
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="/buyer/register">
                                  Sign up as a Stockist 
                                </Dropdown.Item>
                                <Dropdown.Item href="/seller/apply">
                                  Apply as a Brand
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        }
                      </>
                    )}
                  </div>
                </div>
                {categories?.map((cat, index) => (
                  <Dropdown.Item className="collapse-item-container">
                    <Accordion style={{ width: "100%" }}>
                      <MobileMenuAccordian
                        elem={cat}
                        i={index}
                        currentEventKeyCats={currentEventKeyCats}
                        setCurrentEventKeyCats={setCurrentEventKeyCats}
                        setShowCollapseMenu={setShowCollapseMenu}
                      />
                    </Accordion>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          <Navbar.Brand
            href="/"
            style={{ color: "#131412", fontSize: 24, fontWeight: 700 }}
          >
            <img src="/assets/img/stoqli_logo.png" alt="logo" />
          </Navbar.Brand>

          {width > 770 ? (
            <ReworkedSearchBar />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {!toggleSearch && (
                <FaSearch
                  color="black"
                  size={20}
                  style={{ cursor: "pointer", marginRight: 10 }}
                  onClick={() => {
                    setToggleSearch(!toggleSearch);
                    setShowCollapseMenu(false);
                  }}
                />
              )}

              {!showCollapseMenu && !userLog && !sellerLog && (
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      backgroundColor: "black",
                      borderRadius: 7,
                      color: "white",
                      fontSize: 17,
                      fontFamily: "lato",
                      borderColor: "black",
                    }}
                  >
                    Login
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      backgroundColor: "rgba(29, 31, 35, 0.74)",
                      backdropFilter: "blur(11px)",
                    }}
                  >
                    <Dropdown.Item
                      href="/buyer/login"
                      style={{ color: "white", paddingLeft: "1rem" }}
                    >
                      As a Shop
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/seller/login"
                      style={{ color: "white",  paddingLeft: "1rem" }}
                    >
                      As a Brand
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!showCollapseMenu && (userLog || sellerLog) && <IconGroup userLog={userLog} sellerLog={sellerLog} />}
            </div>
          )}

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="collapse-styling"
          >
            <Nav className="me-auto ">
              {categories?.map((cat, index) => (
                <HoverLinks
                  key={index}
                  cat={cat}
                  setSubCategories={setSubCategories}
                  setIsShowing={setIsShowing}
                  setSelectedCategory={setSelectedCategory}
                  setBrandImage={setBrandImage}
                  setShowHoverCat={setShowHoverCat}
                />
              ))}
            </Nav>
          </Navbar.Collapse>
          {width > 770 && (
            <>
              {!userLog ? (
                <div className="login-container">
                  { 
                    sellerLog && sellerLogInfo.id &&
                      <> 
                        <IconGroup search_bar_change={search_bar_change} userLog={userLog} sellerLog={sellerLog} />
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#131412",
                            fontSize: 17,
                            fontWeight: 600,
                            cursor: "pointer",
                            marginBottom: 0,
                            marginRight: 10,
                            marginLeft: 10
                          }}
                          href={`/seller/store/${sellerLogInfo.id}`}
                        >
                          Dashboard
                        </Button>
                      </>
                  }
                  { !userLog && !sellerLog &&
                    <>
                      <Button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          color: "#131412",
                          fontSize: 17,
                          fontWeight: 600,
                          cursor: "pointer",
                          marginBottom: 0,
                          marginRight: 10,
                        }}
                        href="/buyer/login"
                      >
                        Login 
                      </Button>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          style={{
                            background: "#1D1F23",
                            borderRadius: 7,
                            color: "white",
                            fontSize: 15,
                            fontFamily: "lato",
                            borderColor: "black",
                          }}
                        >
                          Free Signup
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{
                            backgroundColor: "rgba(29, 31, 35, 0.74)",
                            backdropFilter: "blur(11px)",
                          }}
                        >
                          <Dropdown.Item
                            href="/buyer/register"
                            style={{ color: "white" }}
                          >
                            Sign up as a Stockist 
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="/seller/apply"
                            style={{ color: "white" }}
                          >
                            Apply as a Brand
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  }
                </div>
              ) : (
                <IconGroup search_bar_change={search_bar_change} userLog={userLog} sellerLog={sellerLog} />
              )}
            </>
          )}
        </Container>
        {width < 770 && <ReworkedSearchBar />}
      </Navbar>
      {isShowing && (
        <HoverMenu noticebar={noticebar} setIsShowing={setIsShowing} selected={selectedCategory} brandImage={brandImage} />
      )}
    </div>
  );
};

export default ReworkedHeaderContainer;
