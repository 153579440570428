import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { setCurrency } from "./redux/actions/currencyActions";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import RequireAuth from "./RequireAuth";
import RequireAuthAdmin from "./RequireAdminAuth";
import RequireAuthBuyer from "./RequireAuthBuyer";
import ProductsByTag from "./pages/public/ProductsByTag";

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

//admin pages
const AdminLogin = lazy(() => import("./pages/admin/login/login"));
const AdminDashboard = lazy(() => import("./pages/admin/dashboard/dashboard"));
const AdminSellerList = lazy(() => import("./pages/admin/seller/sellerList"));
const AdminSellerEdit = lazy(() => import("./pages/admin/seller/editSeller"));
const AdminBuyerList = lazy(() => import("./pages/admin/buyer/buyerList"));
const AdminBuyerEdit = lazy(() => import("./pages/admin/buyer/buyerEdit"));
const AdminNoticebar = lazy(() => import("./pages/admin/noticebar/index"));
const AdminReviewsList = lazy(() =>
  import("./pages/admin/reviews/reviewsList")
);
const ResetPasswordAdmin = lazy(() => import("./pages/admin/resetpassword"));
const AdminOrdersList = lazy(() => import("./pages/admin/orders/OrdersList"));
const AdminOrderDetails = lazy(() =>
  import("./pages/admin/orders/OrderDetail")
);
const AdminCategories = lazy(() => import("./pages/admin/categories/index"));
const AdminAssignee = lazy(() => import("./pages/admin/assignee/index"));
const AdminVAT = lazy(() => import("./pages/admin/vat/index"));
const AdminCheckins = lazy(() => import("./pages/admin/checkins/index"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const BrandsPerCategory = lazy(() => import("./pages/public/BrandsByCategory"));
const SearchResult = lazy(() => import("./pages/public/SearchResult"));
const CategoryFilterProducts = lazy(() =>
  import("./pages/public/CategoryFilterProducts")
);

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

//seller pages
const ApplyToSell = lazy(() => import("./pages/seller/ApplyToSell"));
const ThankYou = lazy(() => import("./pages/seller/ThankYou"));
const RegisterToSell = lazy(() => import("./pages/seller/RegisterToSell"));
const Store = lazy(() => import("./pages/store/store"));
const StoreSetup = lazy(() => import("./pages/store/storeSetup"));
const StoreSettings = lazy(() => import("./pages/store/storeSettings"));
const Products = lazy(() => import("./pages/store/Products/Products"));
const AddProducts = lazy(() => import("./pages/store/Products/addProducts"));
const EditProducts = lazy(() => import("./pages/store/Products/editProduct"));
const ResetPasswordSeller = lazy(() => import("./pages/seller/resetpassword"));
const OrdersListSeller = lazy(() => import("./pages/seller/orders/OrdersList"));
const OrderDetailsSeller = lazy(() =>
  import("./pages/seller/orders/OrderDetails")
);
const SellerMessages = lazy(() => import("./pages/seller/messages/messages"));

//Buyer Pages
const BuyerLogin = lazy(() => import("./pages/buyer/login"));
// const BuyerRegister = lazy(() => import("./pages/buyer/register"));
const WizardBuyerRegister = lazy(() =>
  import("./pages/buyer/Wizard/BuyerRegister")
);
const Home = lazy(() => import("./pages/home/Home"));
const BuyerThanks = lazy(() => import("./pages/buyer/thanks"));
const ResetPasswordBuyer = lazy(() => import("./pages/buyer/resetpassword"));

const Wizard = lazy(() => import("./pages/seller/Wizard/wizard"));

const LandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const StoreView = lazy(() => import("./pages/public/StoreView"));
const OrdersListBuyer = lazy(() => import("./pages/buyer/orders/OrdersList"));
const OrdersDetailBuyer = lazy(() =>
  import("./pages/buyer/orders/OrdersDetail")
);
const BuyerMessages = lazy(() => import("./pages/buyer/messages/messages"));
const BuyerReOrder = lazy(() => import("./pages/buyer/orders/ReOrder"));

const Invoice = lazy(() => import("./pages/invoice/invoice"));
const Statement = lazy(() => import("./pages/statement/statement"));

const App = (props) => {
  useEffect(() => {
    props.setCurrency("RAND");
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  return (
    <div style={{ fontFamily: "lato", backgroundColor: "#FFFFFF" }}>
      <ToastProvider placement="bottom-left">
        <BreadcrumbsProvider>
          <Router>
            <ScrollToTop>
              <Suspense
                fallback={
                  <div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                }
              >
                <Switch>
                  <Route exact path={"/"} component={LandingPage} />
                  <Route exact path={"/home"} component={Home} />
                  
                  <Route
                    exact
                    path={"/brands/:id/category"}
                    component={BrandsPerCategory}
                  />
                  <Route exact path={"/search/:id"} component={SearchResult} />
                  <Route
                    exact
                    path={"/category/:cat"}
                    component={CategoryFilterProducts}
                  />
                  <Route exact path={"/tags"} component={ProductsByTag} />
                  <Route
                    exact
                    path={"/category/:cat/:subcat"}
                    component={CategoryFilterProducts}
                  />
                  <Route
                    exact
                    path={"/category/:cat/:subcat/:secSubCat"}
                    component={CategoryFilterProducts}
                  />
                  {/* Seller Pages */}

                  <Route exact path={"/seller/apply"} component={Wizard} />
                  <Route exact path={"/seller/login"} component={ApplyToSell} />
                  <Route
                    exact
                    path={"/seller/register/:id"}
                    component={RegisterToSell}
                  />
                  <Route exact path={"/seller/thanks"} component={ThankYou} />
                  <Route
                    exact
                    path={"/seller/reset/password/:token"}
                    component={ResetPasswordSeller}
                  />
                  <Route
                    exact
                    path={"/seller/store/:id"}
                    component={RequireAuth(Store)}
                  />
                  <Route
                    exact
                    path={"/seller/store/setup/:id"}
                    component={RequireAuth(StoreSetup)}
                  />
                  <Route
                    exact
                    path={"/seller/store/settings/:id"}
                    component={RequireAuth(StoreSettings)}
                  />
                  <Route
                    exact
                    path={"/seller/store/product/add/:id"}
                    component={RequireAuth(AddProducts)}
                  />
                  <Route
                    exact
                    path={"/seller/store/product/:product_id/edit/:id"}
                    component={RequireAuth(EditProducts)}
                  />
                  <Route
                    exact
                    path={"/seller/store/products/list/:id"}
                    component={RequireAuth(Products)}
                  />
                  <Route
                    exact
                    path={"/seller/order/list/:id"}
                    component={RequireAuth(OrdersListSeller)}
                  />
                  <Route
                    exact
                    path={"/seller/order/:id/details"}
                    component={RequireAuth(OrderDetailsSeller)}
                  />
                  <Route
                    exact
                    path={"/seller/messages"}
                    component={RequireAuth(SellerMessages)}
                  />
                  <Route 
                    exact 
                    path={"/seller/order/:id/invoice"}
                    component={Invoice} 
                  />

                  {/* Buyer Pages */}
                  <Route exact path={"/buyer/login"} component={BuyerLogin} />
                  <Route exact path={"/buyer/login/:id"} component={BuyerLogin} />
                  <Route
                    exact
                    path={"/buyer/register"}
                    component={WizardBuyerRegister}
                  />
                  <Route exact path={"/buyer/thanks"} component={BuyerThanks} />
                  <Route
                    exact
                    path={"/buyer/reset/password/:token"}
                    component={ResetPasswordBuyer}
                  />
                  <Route
                    exact
                    path={"/public/view/store/:id"}
                    component={StoreView}
                  />
                  <Route
                    exact
                    path={"/buyer/order/list/:id"}
                    component={RequireAuthBuyer(OrdersListBuyer)}
                  />
                  <Route
                    exact
                    path={"/buyer/order/:id/details"}
                    component={RequireAuthBuyer(OrdersDetailBuyer)}
                  />
                  <Route
                    exact
                    path={"/buyer/reorder/:id/details"}
                    component={RequireAuthBuyer(BuyerReOrder)}
                  />
                  <Route
                    exact
                    path={"/buyer/messages"}
                    component={RequireAuthBuyer(BuyerMessages)}
                  />
                  <Route 
                    exact 
                    path={"/buyer/order/:id/invoice"}
                    component={Invoice} 
                  />
                  <Route 
                    exact 
                    path={"/buyer/order/:start_date/:end_date/:id/statement"}
                    component={Statement} 
                  />

                  {/* {Admin Pages} */}
                  <Route exact path={"/admin/login"} component={AdminLogin} />
                  <Route
                    exact
                    path={"/admin/reset/password/:token"}
                    component={ResetPasswordAdmin}
                  />
                  
                  <Route
                    exact
                    path={"/admin/dashboard"}
                    component={RequireAuthAdmin(AdminDashboard)}
                  />
                  <Route
                    exact
                    path={"/admin/seller/list"}
                    component={RequireAuthAdmin(AdminSellerList)}
                  />
                  <Route
                    exact
                    path={"/admin/seller/edit/:id"}
                    component={RequireAuthAdmin(AdminSellerEdit)}
                  />
                  <Route
                    exact
                    path={"/admin/buyer/list"}
                    component={RequireAuthAdmin(AdminBuyerList)}
                  />
                  <Route
                    exact
                    path={"/admin/buyer/edit/:id"}
                    component={RequireAuthAdmin(AdminBuyerEdit)}
                  />
                  <Route
                    exact
                    path={"/admin/review/list"}
                    component={RequireAuthAdmin(AdminReviewsList)}
                  />
                  <Route
                    exact
                    path={"/admin/order/list"}
                    component={RequireAuthAdmin(AdminOrdersList)}
                  />
                  <Route
                    exact
                    path={"/admin/order/:id/details"}
                    component={RequireAuthAdmin(AdminOrderDetails)}
                  />
                  <Route 
                    exact 
                    path={"/admin/order/:id/invoice"}
                    component={Invoice} 
                  />
                  <Route 
                    exact 
                    path={"/admin/order/:start_date/:end_date/:id/statement"}
                    component={Statement} 
                  />
                  <Route
                    exact
                    path={"/admin/categories/list"}
                    component={RequireAuthAdmin(AdminCategories)}
                  />
                  <Route
                    exact
                    path={"/admin/assignee/list"}
                    component={RequireAuthAdmin(AdminAssignee)}
                  />
                  <Route
                    exact
                    path={"/admin/noticebar"}
                    component={RequireAuthAdmin(AdminNoticebar)}
                  />
                  <Route
                    exact
                    path={"/admin/vat"}
                    component={RequireAuthAdmin(AdminVAT)}
                  />
                  <Route
                    exact
                    path={"/admin/checkins"}
                    component={RequireAuthAdmin(AdminCheckins)}
                  />
                
                  {/* Shop product pages */}
                  <Route
                    path={"/product/:id"}
                    render={(routeProps) => (
                      <Product
                        {...routeProps}
                        key={routeProps.match.params.id}
                      />
                    )}
                  />

                  {/* Other pages */}
                  <Route path={"/about"} component={About} />
                  <Route path={"/contact"} component={Contact} />
                  <Route path={"/faq"} component={FAQ} />
                  <Route path={"/my-account"} component={MyAccount} />
                  {/* <Route
                  path={"/login-register"}
                  component={LoginRegister}
                /> */}

                  <Route path={"/cart"} component={Cart} />
                  <Route path={"/wishlist"} component={Wishlist} />
                  <Route path={"/compare"} component={Compare} />
                  <Route path={"/checkout"} component={Checkout} />
                  <Route path={"/not-found"} component={NotFound} />
                  <Route exact component={NotFound} />
                </Switch>
              </Suspense>
            </ScrollToTop>
          </Router>
        </BreadcrumbsProvider>
      </ToastProvider>
    </div>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrency: (currencyName) => {
      dispatch(setCurrency(currencyName));
    },
  };
};
export default connect(null, mapDispatchToProps)(multilanguage(App));
