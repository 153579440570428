import React, { useRef, useState, useCallback } from "react";
import { Menu, MenuItem, AsyncTypeahead } from "react-bootstrap-typeahead";

import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import "./headerStyling.css";
import { FaSearch } from "react-icons/fa";
import { useWindowDims } from "../../../helpers/useDimensions";

const ReworkedSearchBar = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [searchKey, setSearchKey] = useState("");
  const ref = useRef();

  const handleSearch = async (query) => {
    setIsLoading(true);
    try {
      let encode = encodeURIComponent(query);
      const response = await axios.get(
        `${config.prod}/api/search/products/${encode}`
      );
      let options = [];
      response.data.products.map((i) =>
        options.push({
          // avatar_url: i.avatar_url,
          isBrand: false,
          id: i.id,
          name: i.name,
        })
      );
      for (let index = 0; index < response.data.brands.length; index++) {
        const brand = response.data.brands[index];
        options.push({
          isFirstBrand: index == 0 ? true : false,
          isBrand: true,
          avatar_url: brand.logo,
          id: brand.id,
          name: brand.name,
        });
      }
      setOptions(options);
      setIsLoading(false);
    } catch (error) {
      console.log("Error getting categories from db: ", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchKey(`${e}`);
  };
  const getSuggestions = useCallback((searchTerm) => {
    handleSearch(searchTerm);
  }, []);
  const keyDownTextArea = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      ref.current.clear();
      history.push(`/search/${searchKey}`);
    }
  };
  const handleSearchClick = (option) => {
    ref.current.clear();
    if (option.isBrand) {
      history.push(`/public/view/store/${option.id}`);
    } else {
      history.push(`/product/${option.id}`);
    }
  };
  return (
    <div className="search-container">
      <AsyncTypeahead
        ref={ref}
        inputProps={{ className: "input-container-custom" }}
        filterBy={() => true}
        id="custom-menu"
        isLoading={isLoading}
        labelKey="name"
        minLength={3}
        onInputChange={(e) => handleSearchChange(e)}
        onKeyDown={(e) => {
          keyDownTextArea(e);
        }}
        onSearch={getSuggestions}
        options={options}
        placeholder="Search for brands & products"
        style={{ color: "red" }}
        className="custom-type-placeholder"
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((option, idx) => (
              <MenuItem
                key={idx}
                onClick={() => handleSearchClick(option)}
                option={option}
                position={idx}
              >
                {option.isBrand && option.isFirstBrand ? (
                  <h4
                    style={{
                      borderTop: "1px solid gray",
                      paddingTop: "5px",
                    }}
                  >
                    Brands
                  </h4>
                ) : null}
                {option.isBrand ? (
                  <img
                    alt={option.name}
                    src={
                      option.avatar_url
                        ? option.avatar_url
                        : `${window.location.origin}/assets/img/banner/banner-4.jpg`
                    }
                    style={{
                      height: "30px",
                      marginRight: "10px",
                      borderRadius: "50%",
                      width: "30px",
                    }}
                  />
                ) : null}
                <span>{option.name}</span>
              </MenuItem>
            ))}
          </Menu>
        )}
      />
      {/* <FaSearch color="black" size={20} className="search-icon" /> */}
    </div>
  );
};

export default ReworkedSearchBar;
