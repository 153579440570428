import PropTypes from "prop-types";
import React from "react";
import ProductgridList from "./ProductgridList";

const ShopProducts = ({ products, layout, parent, getUser, lkey }) => {
  return (
    <div className="shop-bottom-area mt-1">
      <div className={`row ${layout ? layout : ""}`}>
        <ProductgridList
          products={products}
          spaceBottomClass="mb-25"
          layout={layout}
          parent={parent}
          getUser={getUser}
          lkey={lkey}
        />
      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array,
};

export default ShopProducts;
